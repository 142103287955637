<template>
    <div class="base-container give-mark">
        <el-card class="box-card">
            <div class="item" v-loading="loading">
                <el-button v-if="isUpdate != 1 && isUpdate !=5"
                           class="back-btn"
                           size="mini"
                           icon="el-icon-back"
                           @click="onBack()">返回
                </el-button>
                <el-carousel v-if="dataList.length>0" ref="carouselRef" :interval="5000" :initial-index="initialIndex"
                             :autoplay="false"
                             :loop="false" arrow="never"
                             indicator-position="none">
                    <!--评阅模块-->
                    <el-carousel-item v-if="$store.state.teacherType==4" v-for="(item, index) in dataList" :key="index">
                        <div class="giveMark flex">
                            <!-- left -->
                            <div class="mark_left">
                                <i class="arrow el-icon-arrow-left" @click="onPrev()"></i>
                                <i class="arrow el-icon-arrow-right" @click="onNext()"></i>
                                <div class="imgBox"
                                     :class="isBig?'img_big':'img_small'">
                                    <el-image
                                            fit="contain"
                                            :src="$store.getters.getFilePath+item.images_new"
                                            :preview-src-list="isBig?[$store.getters.getFilePath+item.images_new]:[]"
                                            @click="toImgBig(1)"
                                    >
                                    </el-image>
                                    <div class="sign">新</div>
                                </div>
                                <div class="imgBox" :class="!isBig?'img_big':'img_small'">
                                    <el-image
                                            fit="contain"
                                            :src="$store.getters.getFilePath+item.images"
                                            :preview-src-list="!isBig?[$store.getters.getFilePath+item.images]:[]"
                                            @click="toImgBig(2)"
                                    >
                                    </el-image>
                                    <div class="sign">旧</div>
                                </div>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <!--<div>精度：{{item.precision}}</div>-->
                                </div>
                            </div>
                            <!-- right -->
                            <div class="mark_right">
                                <!-- 科目名称 -->
                                <div class="generalReview aa">
                                    <div class="flex">
                                        <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                        <div class="item-box">作品总数: {{$store.state.score_all_sum}}</div>
                                    </div>
                                    <div class="flex" v-if="$store.state.examInfo.exam_type != 2">
                                        <div class="item-box">已经评阅（{{$store.state.score_sum}}）:</div>
                                        <div class="item-box item-box-r">
                                            <el-progress :percentage="$store.state.score_sum_cost"></el-progress>
                                        </div>
                                    </div>
                                </div>
                                <!-- 历史评分 -->
                                <div class="historyScore aa">
                                    <h4>历史评分</h4>
                                    <div class="content flex" v-for="(item2, index2) in item['old_data']" :key="index2">
                                        <template v-if="index2=='初评'">
                                            <div class="item-box flex flex-lf" v-if="item['old_data']">
                                                <div class="item-box-l">{{index2}}评分：</div>
                                                <div class="item-box-r"
                                                     v-if="item2['assess_nums_list'].length>0">
                                                <span v-for="(item3, index3) in item2['assess_nums_list']"
                                                      :key="index3">{{item3.score}}</span>
                                                </div>
                                                <div class="item-box-r" v-else>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                            <div class="item-box flex flex-lf">
                                                <div class="item-box-l">最终分：</div>
                                                <div class="item-box-r" v-if="item2['end'].length>0">
                                                    <span v-for="(item3, index3) in item2['end']"
                                                          :key="index3">
                                                        {{item3.score=='0-100'?index2+'结果待定':item3.score}}
                                                    </span>
                                                </div>
                                                <div class="item-box-r" v-else>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="index2=='复评'">
                                            <div class="item-box flex flex-lf" v-if="item['old_data']">
                                                <div class="item-box-l">{{index2}}评分：</div>
                                                <div class="item-box-r"
                                                     v-if="item2['old'].length>0">
                                                <span v-for="(item3, index3) in item2['old']"
                                                      :key="index3">{{item3.score}}</span>
                                                </div>
                                                <div class="item-box-r" v-else>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <!-- 改分理由 -->
                                <div class="aa">
                                    <h4>改分理由: </h4>
                                    <el-input
                                            type="textarea"
                                            v-model="message"
                                            maxlength="50"
                                            show-word-limit
                                            placeholder="请填写改分理由"
                                    ></el-input>
                                </div>
                                <!-- 当前评分 -->
                                <div class="nowScore aa">
                                    <h4>当前评分: {{item.grade}}</h4>
                                    <div class="nowScore-btn is-score">
                                        <el-button :class="{
                                                        'is-select':(item.grade==item2.score),
                                                        'is-block':(item2.score==100),
                                                   }"
                                                   v-for="(item2, index2) in item.scores" :key="index2"
                                                   @click="giveScore(item.id_card, item2.id)"
                                                   size="mini">
                                            {{item2.score}}
                                        </el-button>
                                    </div>
                                </div>

                                <!--初评 或者 仲裁已评分无分数 或者 初评结果待定(next==35) 不显示跨档评分-->
                                <div style="text-align: center;margin: 10px 0 10px 0;">
                                    <el-button type="primary" @click="setOldScore(item.id_card, item.old_score)">保持原分
                                    </el-button>
                                    <el-button type="primary" @click="getOtherScore">跨档评分</el-button>
                                </div>
                                <!-- 跨档评分 -->
                                <div v-if="isShowOtherScore" class="nowScore aa">
                                    <h4>跨档评分: {{item.grade}}</h4>
                                    <!--<div class="nowScore-btn is-score">
                                        <el-button :class="{
                                                        'is-select':(item.grade==item2.score),
                                                        'is-block':(item2.score==100),
                                                   }"
                                                   v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                                   :key="index2"
                                                   @click="giveScore(item.id_card, item2.id)"
                                                   size="mini">
                                            {{item2.score}}
                                        </el-button>
                                    </div>-->
                                    <div class="nowScore-btn is-score"
                                         v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                         :key="index2">
                                        <el-button v-for="(item3, index3) in item2"
                                                   :key="index3"
                                                   :class="{
                                                        'is-select':(item.grade==item3.score),
                                                        'is-block':(item3.score==100),
                                                   }"
                                                   @click="giveScore(item.id_card, item3.id)"
                                                   size="mini">
                                            {{item3.score}}
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <!--阅卷、第二次阅卷、仲裁模块-->
                    <el-carousel-item v-else v-for="(item, index) in dataList" :key="index">
                        <div class="giveMark flex">
                            <!-- left -->
                            <div class="mark_left">
                                <i v-if="isUpdate != 2" class="arrow el-icon-arrow-left" @click="onPrev()"></i>
                                <i v-if="isUpdate != 2" class="arrow el-icon-arrow-right" @click="onNext()"></i>

                                <div class="imgBox img_big">
                                    <el-image
                                            fit="contain"
                                            :src="$store.getters.getFilePath+item.images"
                                            :preview-src-list="[$store.getters.getFilePath+item.images]"
                                    >
                                    </el-image>
                                </div>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <!--<div>精度：{{item.precision}}</div>-->
                                </div>
                            </div>
                            <!-- right -->
                            <div class="mark_right">
                                <!-- 科目名称 -->
                                <div class="generalReview aa">
                                    <div class="flex">
                                        <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                        <div class="item-box" v-if="$store.state.examInfo.exam_type != 2">作品总数:
                                            {{$store.state.score_all_sum}}
                                        </div>
                                        <div class="item-box" v-else>作品总数: {{total}}</div>
                                    </div>
                                    <div class="flex" v-if="$store.state.examInfo.exam_type != 2">
                                        <div class="item-box">已经评阅（{{$store.state.score_sum}}）:</div>
                                        <div class="item-box item-box-r">
                                            <el-progress :percentage="$store.state.score_sum_cost"></el-progress>
                                        </div>
                                    </div>
                                </div>

                                <!-- 历史评分 -->
                                <!--未评分 或者 仲裁已评分无分数 显示历史评分-->
                                <!--<div class="historyScore aa"
                                     v-if="isUpdate==1||((isUpdate==2||isUpdate==3||isUpdate==5)&&$store.state.teacherType==2)">
                                    <h4>历史评分</h4>

                                    <div class="content flex" v-for="(item2, index2) in item['old_data']" :key="index2">
                                        <template v-if="index2=='初评'">
                                            <div class="item-box flex flex-lf" v-if="item['old_data']">
                                                <div class="item-box-l">{{index2}}评分：</div>
                                                <div class="item-box-r"
                                                     v-if="item2['assess_nums_list'].length>0">
                                                <span v-for="(item3, index3) in item2['assess_nums_list']"
                                                      :key="index3">{{item3.score}}</span>
                                                </div>
                                                <div class="item-box-r" v-else>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                            <div class="item-box flex flex-lf">
                                                <div class="item-box-l">最终分：</div>
                                                <div class="item-box-r" v-if="item2['end'].length>0">
                                                    <span v-for="(item3, index3) in item2['end']"
                                                          :key="index3">
                                                        {{item3.score=='0-100'?index2+'结果待定':item3.score}}
                                                    </span>
                                                </div>
                                                <div class="item-box-r" v-else>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="index2=='复评'">
                                            <div class="item-box flex flex-lf" v-if="item['old_data']">
                                                <div class="item-box-l">{{index2}}评分：</div>
                                                <div class="item-box-r"
                                                     v-if="item2['assess_nums_list'].length>0">
                                                <span v-for="(item3, index3) in item2['assess_nums_list']"
                                                      :key="index3">{{item3.score}}</span>
                                                </div>
                                                <div class="item-box-r" v-else>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                            <div class="flex flex-between">
                                                <div class="item-box flex flex-lf">
                                                    <div class="item-box-l">{{index2}}仲裁：</div>
                                                    <div class="item-box-r"
                                                         v-if="item2['zc'].length>0">
                                                        <span v-for="(item3, index3) in item2['zc']"
                                                              :key="index3">{{item3.score}}</span>
                                                    </div>
                                                    <div class="item-box-r" v-else>
                                                        <span>-</span>
                                                    </div>
                                                </div>
                                                <div class="item-box flex flex-lf">
                                                    <div class="item-box-l">最终分：</div>
                                                    <div class="item-box-r"
                                                         v-if="item2['end'].length>0">
                                                        <span v-for="(item3, index3) in item2['end']"
                                                              :key="index3">
                                                            {{item3.score=='0-100'?index2+'结果待定':item3.score}}
                                                        </span>
                                                    </div>
                                                    <div class="item-box-r" v-else>
                                                        <span>-</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>-->
                                <!--<div class="content flex" v-for="(item2, index2) in item['old_data']" :key="index2">
                                    <div class="item-box flex flex-lf" v-if="item['old_data']">
                                        <div class="item-box-l">{{index2}}评分：</div>
                                        <div class="item-box-r"
                                             v-if="item2['assess_nums_list'].length>0">
                                            <span v-for="(item3, index3) in item2['assess_nums_list']"
                                                  :key="index3">{{item3.score}}</span>
                                        </div>
                                        <div class="item-box-r" v-else>
                                            <span>-</span>
                                        </div>
                                    </div>
                                    <div class="item-box flex flex-lf" v-if="item['old_data']&&index2!='复评'">
                                        <div class="item-box-l">二次评分：</div>
                                        <div class="item-box-r"
                                             v-if="item2['assess_nums_list_two'].length>0">
                                            <span v-for="(item3, index3) in item2['assess_nums_list_two']"
                                                  :key="index3">{{item3.score}}</span>
                                        </div>
                                        <div class="item-box-r" v-else>
                                            <span>-</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-between">
                                        <div class="item-box flex flex-lf">
                                            <div class="item-box-l">{{index2}}仲裁：</div>
                                            <div class="item-box-r"
                                                 v-if="item2['zc'].length>0">
                                            <span v-for="(item3, index3) in item2['zc']"
                                                  :key="index3">{{item3.score}}</span>
                                            </div>
                                            <div class="item-box-r" v-else>
                                                <span>-</span>
                                            </div>
                                        </div>
                                        <div class="item-box flex flex-lf">
                                            <div class="item-box-l">最终分：</div>
                                            <div class="item-box-r"
                                                 v-if="item2['end'].length>0">
                                            <span v-for="(item3, index3) in item2['end']"
                                                  :key="index3">
                                                {{item3.score=='0-100'?index2+'结果待定':item3.score}}
                                            </span>
                                            </div>
                                            <div class="item-box-r" v-else>
                                                <span>-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                <!-- 当前评分 -->
                                <!--<div class="nowScore aa" v-if="item.scores">
                                    <h4>当前评分: {{item.grade}}</h4>
                                    <div class="nowScore-btn is-score">
                                        <el-button :class="{
                                                        'is-select':(item.grade==item2.score),
                                                        'is-block':(item2.score==100),
                                                   }"
                                                   v-for="(item2, index2) in item.scores" :key="index2"
                                                   @click="giveScore(item.id_card, item2.id)"
                                                   size="mini">
                                            {{item2.score}}
                                        </el-button>
                                    </div>
                                </div>-->
                                <!--当前评分-仲裁已评分无分数-->
                                <!--<div class="nowScore aa" v-if="item.score">
                                     <h4>当前评分: {{item.score[0]['score']}}</h4>
                                </div>-->

                                <!--初评 或者 仲裁已评分无分数 或者 初评结果待定(next==35) 不显示跨档评分-->
                                <!--$store.state.assess_num > 1 &&-->
                                <!--<div v-if="!((isUpdate==2||isUpdate==3||isUpdate==5)&&$store.state.teacherType==2)
                                && !(item['old_data']&&item['old_data']['初评']['end'].length>0&&[0]['score']=='0-100')
                                && !(item['next']&&item['next']==35)"
                                     style="text-align: center;margin: 10px 0 10px 0;">
                                    <el-button type="primary" @click="getOtherScore">跨档评分</el-button>
                                </div>-->
                                <!-- 跨档评分 -->
                                <!--v-if="$store.state.assess_num > 1 && isShowOtherScore"-->
                                <template v-if="$store.state.examInfo.exam_type != 2">
                                    <div class="nowScore aa">
                                        <h4>评分: {{item.is_wait==0?item.grade:''}}</h4>
                                        <!--<div class="nowScore-btn is-score">
                                            <el-button v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                                       :key="index2"
                                                       :class="{
                                                           'is-select':(item.is_wait==0&&item.grade==item2.score),
                                                           'is-block':(item2.score==100),
                                                       }"
                                                       @click="giveScore(item.id_card, item2.id)"
                                                       :disabled="loading"
                                                       size="mini"
                                            >
                                                {{item2.score}}
                                            </el-button>
                                        </div>-->
                                        <div class="nowScore-btn is-score"
                                             v-for="(item2, index2) in $store.state.otherScoreRoteList"
                                             :key="index2">
                                            <el-button v-for="(item3, index3) in item2"
                                                       :key="index3"
                                                       :class="{
                                                           'is-select':(item.is_wait==0&&item.grade==item3.score),
                                                           'is-block':(item3.score==100),
                                                       }"
                                                       @click="giveScore(item.id_card, item3.id)"
                                                       size="mini">
                                                {{item3.score}}
                                            </el-button>
                                        </div>
                                    </div>
                                    <div class="nowScore aa"
                                         v-if="$store.state.teacherType == 1 && (isUpdate ==1 ||isUpdate == 6)">
                                        <h4>分档: {{item.is_wait==1?item.grade:''}}</h4>
                                        <div class="nowScore-btn is-wait">
                                            <el-button v-for="(item2, index2) in $store.state.levelScoreRoteList"
                                                       :key="index2"
                                                       :class="{
                                                           'is-select':(item.is_wait==1&&item.grade==item2.score),
                                                           'is-block':(item2.score==100),
                                                       }"
                                                       @click="setWait(item.id_card, item2.id)"
                                                       :disabled="loading"
                                                       size="mini"
                                            >
                                                {{item2.score}}
                                            </el-button>
                                        </div>
                                    </div>
                                </template>
                                <div v-if="$store.state.teacherType == 1 && isUpdate == 1"
                                     style="text-align: center;margin: 40px 0 10px 0;">
                                    <el-button v-if="$store.state.examInfo.exam_type == 2" class="normal_btn"
                                               @click="giveScore(item.id_card, 10)">无异常
                                    </el-button>
                                    <el-button type="primary" @click="setAbnormal(item.id_card, 1)">提交异常</el-button>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
            </div>
        </el-card>
        <!--标记异常弹框-->
        <abnormal-box
                :dialogVisibleFu="dialogVisibleFu"
                :dataObjFu="dataObjFu"
                @closeAbnormalBox="closeAbnormalBox"
        ></abnormal-box>
        <!--message弹框-->
        <message-dialog
                :dialogVisibleFu="messageDialogFu"
                :isUpdateFu="isUpdate"
                :dataObjFu="msgObjFu"
                @closeDialog="closeMessageDialog"
        ></message-dialog>
    </div>
</template>

<script>
    import {
        getStudentList,
        getStudentListEnd,
        getStudentList2,
        setYjScore,
        setYjScoreEnd,
        setYjScore2,
        getStudentListT,
        updateYjScoreT
    } from "../api/mark";
    import {
        getStudentScoreList,
        getStudentScoreList2,
        getStudentScoreListEnd,
        updateYjScore,
        updateYjScore2
    } from "../api/score";
    import {
        getWaitList,
        setYjWait,
        getYjScoreLevel,
    } from "../api/wait";
    import {
        getYjScoreAll
    } from "../api/select";
    import AbnormalBox from "./AbnormalBox";
    import MessageDialog from "./MessageDialog";
    import {mapMutations} from "vuex";

    export default {
        name: "GiveMark2",
        props: {
            isTypeFu: [Number, String]
        },
        components: {AbnormalBox, MessageDialog},
        data() {
            return {
                query: {
                    start: 1,
                    size: 1,
                },
                total: 0,
                dataList: [],
                loading: false,
                initialIndex: 0,
                isType: 0,// 1-作品评分 2-已评阅 3-分档评分
                isUpdate: 1, // 是否是修改 1-未评分 2-历史评阅(已评分无分数) 3-作品对比(已评分无分数) 4-已评分有分数 5-已评阅页面(无分数) 6-分档评分
                dialogVisibleFu: false,// 控制标记异常弹框显示与隐藏
                dataObjFu: {
                    message: '',
                    id_card: null,
                    status: null// 1-标为异常 2-取消异常
                },
                isShowOtherScore: false,// 是否显示跨档评分 true-显示 false-不显示
                message: null,// 改分理由
                isBig: true,// 图片放大
                isDisKeyDown: false,// true-禁用keyDown
                messageDialogFu: false,// 控制message弹框显示与隐藏
                msgObjFu: {},
            };
        },
        mounted() {
            if (this.isTypeFu == 1) {
                this.isUpdate = 1;
                if (!this.$store.state.levelScoreRoteList
                    || this.$store.state.levelScoreRoteList.length <= 0) {
                    this.getLevelScore();
                }
            } else {
                let query = this.$route.query;
                let queryKey = this.$route.query;
                if (queryKey && Object.keys(queryKey).length > 0) {
                    this.query.start = Number(query.start);
                    this.query.order = Number(query.order);
                    if (this.isTypeFu == 3) {
                        if (query.scores) {
                            this.query.scores = query.scores;
                        }
                        this.isUpdate = 6;
                    } else if (query.scores) {
                        this.query.scores = query.scores;
                        this.isUpdate = 4;
                    } else if (this.query.order == 2) {
                        this.isUpdate = 2;
                    } else {
                        this.isUpdate = 3;
                    }
                } else {
                    this.query.order = 1;
                    this.isUpdate = 5;
                }
            }
            this.getList(true);
            if (!this.$store.state.otherScoreRoteList
                || this.$store.state.otherScoreRoteList.length <= 0) {
                this.getOtherScore();
            }
            this.keyDown();
        },
        methods: {
            ...mapMutations([
                "updateOtherScoreRoteList",
                "updateLevelScoreRoteList",
            ]),
            // 监听键盘-左右键翻页
            keyDown() {
                document.onkeydown = (e) => {
                    //事件对象兼容
                    let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
                    //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                    //左
                    if (e1 && e1.keyCode == 37 && !this.isDisKeyDown) {
                        // 按下左箭头
                        this.onPrev();
                    } else if (e1 && e1.keyCode == 39 && !this.isDisKeyDown) {
                        // 按下右箭头
                        this.onNext();
                    }
                }
            },
            // 返回
            onBack() {
                let size = 0,
                    start = 0,
                    start_key = '',
                    size_key = '',
                    path = '';
                if (this.isUpdate == 2) {
                    start_key = 'history_start';
                    size_key = 'history_size';
                    path = '/score/history';
                } else if (this.isUpdate == 3) {
                    start_key = 'contrcat_start';
                    size_key = 'contrcat_size';
                    path = '/score/contrast';
                } else if (this.isUpdate == 4) {
                    start_key = 'score_start_' + this.query.scores;
                    size_key = 'score_size_' + this.query.scores;
                    path = '/score/scoreRote/' + this.query.scores;
                } else if (this.isUpdate == 6) {
                    start_key = 'wait_start';
                    size_key = 'wait_size';
                    path = '/wait/list';
                }
                size = window.localStorage.getItem(size_key);
                start = this.query.start % size == 0 ? this.query.start / size : Math.ceil(this.query.start / size);
                window.localStorage.setItem(start_key, start.toString());
                return this.$router.replace(path);
            },
            toImgBig(type) {
                if ((type == 1 && this.isBig)
                    || (type == 2 && !this.isBig)) {
                    return false;
                }
                this.isBig = !this.isBig;
            },
            // 保持原分
            setOldScore(id_card, scoresId) {
                this.message = '保持原分';
                this.giveScoreRequestHandle(updateYjScoreT, id_card, scoresId)
            },
            // 获取评分数据(跨档评分)
            getOtherScore() {
                let _this = this;
                getYjScoreAll({})
                    .then(res => {
                        if (res.data.code != 200) {
                            return _this.$message.error(res.data.message);
                        }
                        _this.isShowOtherScore = true;
                        _this.updateOtherScoreRoteList(res.data.data.scores)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 获取分档数据列表
            getLevelScore() {
                let _this = this;
                getYjScoreLevel({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return _this.$message.error(res.data.message);
                        }
                        _this.updateLevelScoreRoteList(res.data.data.scores)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 上一页按钮
            onPrev() {
                if (this.query.start > 1) {
                    this.query.start--;
                    this.routerSkip();
                    this.isShowOtherScore = false;
                    this.getList(true);
                } else {
                    this.$message.info('到顶了');
                }
            },
            // 下一页按钮
            onNext() {
                if (this.query.start < this.total) {
                    this.query.start++;
                    this.routerSkip();
                    this.isShowOtherScore = false;
                    this.getList(true);
                } else {
                    this.$message.info('到底了');
                }
            },
            // 打分跳转
            toNextHandle(api = null) {
                if (this.isUpdate == 2) {
                    // 历史评阅-评阅完成跳转回列表
                    return this.$router.replace('/score/history');
                }
                let judge = this.query.start < this.total;
                if (this.isUpdate == 3 || ((this.isUpdate == 6 && !this.query.scores) && api == 'setYjWait')) {
                    // 作品对比、分档评阅(分档无分数)到最后一个时 评阅完成start会加1 所有需要给判断加上=
                    judge = this.query.start <= this.total
                }
                if (judge) {
                } else {
                    this.query.start = 1;
                    // if (this.isUpdate == 2 && this.total > 1) {
                    //     // 历史评阅到最后一个时 评阅完成会回到第一个 所有需要跳转到第二个
                    //     this.query.start = 2;
                    // } else {
                    //     this.query.start = 1;
                    // }
                }
                if (this.isUpdate != 1 && this.isUpdate != 5) {
                    this.routerSkip();
                }
                this.getList();
            },
            // 打分跳转（teacherType==4）
            toNextHandle2() {
                if (this.query.start < this.total) {
                    this.query.start++;
                    this.isShowOtherScore = false;
                    this.getList();
                } else {
                    this.query.start = 1;
                    this.getList();
                }
            },
            // 路由跳转query参数组装
            routerSkip() {
                let query = {};
                if (this.isUpdate == 4 || (this.isUpdate == 6 && this.query.scores)) {
                    query = {
                        start: this.query.start,
                        order: this.query.order,
                        scores: this.query.scores,
                    }
                } else if (this.isUpdate == 2 || this.isUpdate == 3 || (this.isUpdate == 6 && !this.query.scores)) {
                    query = {
                        start: this.query.start,
                        order: this.query.order,
                    }
                }
                this.$router.push({query})
            },
            // 子组件触发，关闭message弹框
            closeMessageDialog(val, type) {
                this.messageDialogFu = val;
                if (type) {
                    this.setScoreNextHandle();
                }
            },
            // 子组件触发，关闭标记异常弹框
            closeAbnormalBox(val, id_card) {
                this.dialogVisibleFu = val;
                this.isDisKeyDown = false;
                if (id_card) {
                    this.toNextHandle();
                }
            },
            // 标记异常
            setAbnormal(id_card, type) {
                this.dataObjFu = {
                    message: '',
                    id_card: id_card,
                    status: type
                }
                this.dialogVisibleFu = true;
                this.isDisKeyDown = true;
            },
            // 分档评分
            setWait(id_card, scoresId) {
                console.log(id_card, scoresId, this.loading)
                if (this.loading) {
                    return;
                }
                this.loading = true;
                let obj = {
                    id_card: id_card,
                    score: scoresId,
                    status: 1,
                }
                setYjWait(obj)
                    .then(res => {
                        console.log(res);
                        if (res.data.code != 200) {
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.isShowOtherScore = false;
                        this.message = null;
                        this.isBig = true;
                        // this.loading = false;
                        if (this.isUpdate == 6 && !this.query.scores) {
                            this.query.start = this.query.start + res.data.data.next;
                        }
                        if (this.$store.state.teacherType == 4) {
                            this.toNextHandle2();
                        } else {
                            this.toNextHandle('setYjWait');
                        }
                        this.$message.success(res.data.message);

                    })
                    .catch(error => {
                        console.log(error);
                        this.isChecked = false;
                    });
            },
            // 打分
            giveScore(id_card, scoresId) {
                if (this.isUpdate != 1) {
                    if (this.$store.state.teacherType == 1) {
                        if (this.isUpdate == 6) {
                            // 分档评分学生打分
                            this.giveScoreRequestHandle(setYjScore, id_card, scoresId, 1)
                        } else {
                            this.giveScoreRequestHandle(updateYjScore, id_card, scoresId, 1)
                        }
                    } else if (this.$store.state.teacherType == 3) {
                        this.giveScoreRequestHandle(updateYjScore2, id_card, scoresId)
                    }
                } else {
                    if (this.$store.state.teacherType == 1) {
                        this.giveScoreRequestHandle(setYjScore, id_card, scoresId, 1)
                    } else if (this.$store.state.teacherType == 2) {
                        this.giveScoreRequestHandle(setYjScoreEnd, id_card, scoresId)
                    } else if (this.$store.state.teacherType == 3) {
                        this.giveScoreRequestHandle(setYjScore2, id_card, scoresId)
                    } else if (this.$store.state.teacherType == 4) {
                        if (!this.message) {
                            return this.$message.info('请填写改分理由')
                        }
                        this.giveScoreRequestHandle(updateYjScoreT, id_card, scoresId)
                    }
                }
            },
            // 打分请求方法
            giveScoreRequestHandle(api, id_card, scoresId, type = false) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                let obj = {
                    score: scoresId,
                    id_card: id_card
                };
                if (this.$store.state.teacherType == 4) {
                    obj = {
                        score: scoresId,
                        id_card: id_card,
                        message: this.message
                    }
                }
                if (type) obj['qz'] = type;
                api(obj)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.loading = false;
                            if (res.data.code == 40000123
                                && this.$store.state.examInfo.exam_ts > 0) {
                                this.messageDialogFu = true;
                                this.msgObjFu = {
                                    score: scoresId,
                                    id_card: id_card,
                                    message: this.message,
                                    showMsg: res.data.message,
                                };
                                return false;
                            }
                            return this.$message.error(res.data.message);
                        }
                        this.setScoreNextHandle();
                        this.$message.success(res.data.message);
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
            // 打完分的操作
            setScoreNextHandle() {
                this.$store.dispatch('GenerateRoutes').then(response => {
                }).catch(error => {
                })
                this.isShowOtherScore = false;
                this.message = null;
                this.isBig = true;
                // this.loading = false;
                if (this.isUpdate == 3) {
                    this.query.start = this.query.start + res.data.data.next;
                }
                if (this.$store.state.teacherType == 4) {
                    this.toNextHandle2();
                } else {
                    this.toNextHandle();
                }
            },
            getList(type = false) {
                // false-打分跳转  true-非打分跳转
                if (this.isUpdate != 1) {
                    // 获取已评分数据列表
                    if (this.$store.state.teacherType == 1) {
                        if (this.isUpdate == 6) {
                            // 获取分档评分数据
                            this.getUpdateList(getWaitList, type);
                        } else {
                            this.getUpdateList(getStudentScoreList, type);
                        }
                    } else if (this.$store.state.teacherType == 3) {
                        this.getUpdateList(getStudentScoreList2, type);
                    } else if (this.$store.state.teacherType == 2) {
                        this.getUpdateList(getStudentScoreListEnd, type);
                    }
                } else {
                    // 获取未评分数据列表
                    if (this.$store.state.teacherType == 1) {
                        this.requestHandle(getStudentList, type)
                    } else if (this.$store.state.teacherType == 2) {
                        this.requestHandle(getStudentListEnd, type)
                    } else if (this.$store.state.teacherType == 3) {
                        this.requestHandle(getStudentList2, type)
                    } else if (this.$store.state.teacherType == 4) {
                        this.requestHandle(getStudentListT, type)
                    }
                }

            },
            // 获取已评分数据请求方法
            getUpdateList(api, type) {
                if (type) {
                    if (this.loading) {
                        return;
                    }
                    this.loading = true;
                }
                api(this.query)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.message = null;
                        this.isBig = true;
                        this.total = res.data.data.sum ? res.data.data.sum : res.data.data.count;
                        this.dataList = res.data.data.list;
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
                this.$forceUpdate();
            },
            // 获取列表数据请求方法
            requestHandle(api, type) {
                if (type) {
                    if (this.loading) {
                        return;
                    }
                    this.loading = true;
                }
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.message = null;
                        this.isBig = true;
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.count;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                this.$forceUpdate();
            }
        },
        watch: {
            isTypeFu(val) {
                this.isType = val;
            },
            '$route.query'(val) {
                if (this.isTypeFu == 1) {
                    this.query.start = 1;
                    delete this.query.scores;
                    delete this.query.order;
                    this.isUpdate = 1;
                    if (!this.$store.state.levelScoreRoteList
                        || this.$store.state.levelScoreRoteList.length <= 0) {
                        this.getLevelScore();
                    }
                } else {
                    let query = val;
                    let queryKey = val;
                    if (queryKey && Object.keys(queryKey).length > 0) {
                        this.query.start = Number(query.start);
                        this.query.order = Number(query.order);
                        if (this.isTypeFu == 3) {
                            if (query.scores) {
                                this.query.scores = query.scores;
                            }
                            this.isUpdate = 6;
                        } else if (query.scores) {
                            this.query.scores = query.scores;
                            this.isUpdate = 4;
                        } else if (this.query.order == 2) {
                            this.isUpdate = 2;
                        } else {
                            this.isUpdate = 3;
                        }
                    } else {
                        this.query.start = 1;
                        this.query.order = 1;
                        delete this.query.scores;
                        this.isUpdate = 5;
                    }
                }

                this.getList(true);
                if (!this.$store.state.otherScoreRoteList
                    || this.$store.state.otherScoreRoteList.length <= 0) {
                    this.getOtherScore();
                }
            },
        },
        destroyed() {
            // 删除事件处理器
            document.onkeydown = null;
        },
    };
</script>

<style scoped lang="scss">
    @import "../assets/css/giveMark";

    .normal_btn {
        margin-right: 20px;
        color: #409EFF;
        border-color: #409EFF;
        background-color: #ffffff;

        &:hover {
            background-color: #ecf5ff;
        }
    }
</style>
